<template>
  <div class="">
    <el-dialog title="" :visible.sync="dialogVisible" width="600px">
      <div class="plot">
        <div class="plot_name">意向小区</div>
        <div class="plot_ipt">
          <el-input
            placeholder="请输入搜索内容"
            v-model="name"
            class="input-with-select"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="prefix"
            >
            </i>
            <el-button slot="append" @click="search">搜索</el-button>
            
          </el-input>
        </div>
        <ul class="plot_list">
          <li :class="{active:item.checked}" @click="item.checked = !item.checked" v-for="(item,index) in plotList" :key="index">{{ item.name }}</li>
        </ul>
        <div class="plot_footer center">
          <el-button @click="submit">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:'',//
      page:1,
      dialogVisible: false,
      plotList:[],//
    };
  },
  methods: {
    submit () {
      if (!this.plotList.some(item=>item.checked)) this.$message.error('请选择小区');
      let data = this.plotList.filter(item=>item.checked)
      this.$emit('suspend',data)
      this.dialogVisible = false
      setTimeout(() => {
        this.plotList.forEach(element => {
          element.checked = false
        });
      }, 1000);
    },
    search () {
      this.page = 1
      this.getPlotList()
    },
    open() {
      this.getPlotList()
      this.dialogVisible = true;
    },
    //获取小区列表
    getPlotList () {
      this.$axios.xiaoQuList({
        name:this.name,
        page:this.page,
        pageNum:1000000
      }).then(res=>{
        res.data.data.forEach(element => {
          element.checked = false
        });
        this.plotList = res.data.data
      })
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {padding: 0;}
/deep/ .el-dialog__body {padding: 40px;}
.plot_name {
  color: #333333;
  font-size: 22px;
  text-align: center;
}
.plot_list {
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    line-height: 28px;
    text-align: center;
    color: #666666;
    height: 28px;
    font-size: 14px;
    padding: 0 10px;
    margin: 20px 10px 0 15px;
    background: #EBEBEB;
    border-radius: 3px;
  }
  .active {
    color: #3273F6;
    background: #E1EBFF;
  }
}
.plot_footer {
  margin-top: 40px;
   /deep/ .el-button {
     
     width: 400px;
    height: 56px;
    color: #FFFFFF;
    font-size: 18px;
    background: #3273F6;
    border-radius: 28px;
   }
  
}
.plot_ipt {
  margin: 30px 0 10px 0;
  /deep/ .el-input__inner {
    height: 48px;
    border-right: none;
  }
  /deep/ .el-button {
    width: 120px;
    height: 48px;
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 0;
    background: #3273F6;
  }
}
</style>
