<template>
  <div class="">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="phone">
        <div class="phone_name">添加联系方式</div>
        <div class="phone_ipt">
          <el-input maxlength="11" v-model="phone" placeholder="请输入联系方式"></el-input>
        </div>
        <div class="phone_footer">
          <el-button type="primary" round @click="submit">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      phone:'',
      dialogVisible: false
    }
  },
  methods:{
    submit () {
      if (!this.phone.match(/^(0|86|17951)?1[3456789]\d{9}$/)) return this.$message.error('请输入正确的联系方式');
      this.dialogVisible = false
      this.$emit('succeed',this.phone)
      setTimeout(() => {
        this.phone = ''
      }, 1000);
    },
    open () {
      this.dialogVisible = true
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {padding: 0;}
/deep/ .el-dialog__body {
  padding: 60px 100px;
}
.phone {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  .phone_name {
    color: #333333;
    font-size: 22px;
    text-align: center;
  }
  .phone_ipt {
    margin: 40px 0 60px 0;
  }
  .phone_footer {
    /deep/ .el-button {
      width: 100%;
      height: 56px;
      background: #3273F6;
      border-radius: 28px;
    }
  }
}
</style>
